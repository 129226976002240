import { useEffect, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';

import { LogEvent } from '@analytics';
import { useGetUser } from '@db/collections';
import { useLocalStorage } from '@uidotdev/usehooks';
import {
  NewBottomSheet,
  NewBottomSheetContent,
  NewBottomSheetHeader,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
  NewBoxButton,
  Spacer,
} from 'design-system';

import { NeedLoginBottomSheet } from '@components/NeedLoginBottomSheet/NeedLoginBottomSheet';

import { api } from '@apis/hc';

import { useWindowSize } from '@hooks';

interface PostCodeProps {
  setAddress: (address: string) => void;
  setLocation: (location: {
    latitude: number;
    longitude: number;
    roadAddress: string;
    jibunAddress: string;
  }) => void;
  onClose: () => void;
}

export const PostCode = ({ setAddress, setLocation, onClose }: PostCodeProps) => {
  const { height: innerHeight } = useWindowSize();

  const handleComplete = async (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setAddress(fullAddress);
    onClose();

    const result = await (
      await api.search.address.by.term.$get({
        query: {
          query: data.address,
        },
      })
    ).json();

    const { x, y } = (result as any)?.addresses[0] || { x: 0, y: 0 };

    if (x && y) {
      setLocation({
        latitude: Number(y),
        longitude: Number(x),
        roadAddress: data.address ?? '',
        jibunAddress: data.jibunAddress ?? '',
      });
    }
  };
  return (
    <div>
      <DaumPostcodeEmbed
        style={{ width: '100%', height: innerHeight - 200 }}
        onComplete={handleComplete}
      />
    </div>
  );
};

export const PostCodeBottomSheet = ({
  children,
  popover,
}: {
  children: (address: string) => React.ReactNode;
  popover?: ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => React.ReactNode;
}) => {
  const { user } = useGetUser();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const [isOpenPostCode, setIsOpenPostCode] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useLocalStorage('user-info', {
    targetLicense: null,
    location: null,
  } as {
    targetLicense: LicenseCode | null;
    location: ExploreSearchData | null;
  });
  const [searchedAddress, setSearchedAddress] = useState<ExploreSearchData | null>(null);

  useEffect(() => {
    if (!user) return;

    if (user.address) {
      if (userInfo.location) {
        setUserInfo({
          ...userInfo,
          location: {
            ...userInfo.location,
          },
        });
      }
    } else if (userInfo && userInfo.location) {
      const { roadAddress } = userInfo.location;

      LogEvent.활성화.addressEnter('auto_input');

      setPopoverOpen(true);
      user.updateAddress({
        address: roadAddress,
        detailAddress: '',
      });
    }
  }, [user?.address, userInfo.location?.jibunAddress]);

  // 감섹힌 주소로 유저 정보를 override
  useEffect(() => {
    if (user?.address && searchedAddress && userInfo.location) {
      setUserInfo({
        ...userInfo,
        location: {
          ...userInfo.location,
          ...searchedAddress,
        },
      });
    }
  }, [searchedAddress]);
  return (
    <NewBottomSheet open={isOpenPostCode} onOpenChange={setIsOpenPostCode}>
      <NewBottomSheetTrigger
        className="relative w-full"
        onClick={(e) => {
          setPopoverOpen(false);
          if (!user) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        {popover && user && (
          <div onClick={(e) => e.stopPropagation()}>
            {popover({ isOpen: popoverOpen, onClose: () => setPopoverOpen(false) })}
          </div>
        )}
        {
          <NeedLoginBottomSheet
            title="예약을 위해 추가 정보가 필요해요."
            description="지금까지 입력한 정보는 그대로 유지돼요."
          >
            <div>{children(user?.address ?? '')}</div>
          </NeedLoginBottomSheet>
        }
      </NewBottomSheetTrigger>
      <NewBottomSheetContent className="w-full">
        <NewBottomSheetHeader>
          <NewBottomSheetTitle>주소를 입력해주세요</NewBottomSheetTitle>
        </NewBottomSheetHeader>
        <PostCode
          setAddress={async (fullAddress) => {
            LogEvent.활성화.addressEnter('manually_input');
            if (!user?.uid) return;

            await user.updateAddress({
              address: fullAddress,
              detailAddress: '',
            });
          }}
          setLocation={(location) => {
            setSearchedAddress({ ...location, distance: 0 });
          }}
          onClose={() => {
            setIsOpenPostCode(false);
          }}
        />
        <Spacer className="h-16" />
        <NewBoxButton label="완료" fill styles="filled-black" />
      </NewBottomSheetContent>
    </NewBottomSheet>
  );
};
