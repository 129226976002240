import { useEffect } from 'react';
import { useState } from 'react';

import { getDeviceByUserAgent } from '@utils/Common';

export const useIsIOSApp = () => {
  const [isIOSApp, setIsIOSApp] = useState(false);

  useEffect(() => {
    const device = getDeviceByUserAgent();
    setIsIOSApp(device === 'IOS app');
  }, []);

  return isIOSApp;
};
