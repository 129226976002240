import { useMemo } from 'react';

import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { useFeatureFlagVariantKey } from 'posthog-js/react';

import { shortReservationAtoms, shortReservationDateTimeAtom } from '../store/atoms';

const ACADEMY_IDS_TO_IGNORE = [
  // 방문 연수 진행 등 테스트에 넣을 수 없는 학원들
  'z5NDkv4bqsOVF5i0KEHy', // 하예서부
  'mdoxbMRnxWMSOv9TOK4Q', // 부림
  '3mi3ZvqYS275OuGAumFN', // 천안중앙
  'jV8NSCZ8tOhWPiE0tgh7', // 금산
  'Grp2b7xJ160QFafLRb9C', // 강북삼성
];

/**
 * 예약 퍼널 줄이기 AB 테스트 적용해야되는 케이스인가
 *
 * @returns 예약 퍼널 줄이기 AB 테스트 적용해야되는 케이스인가 여부
 */
export const useIsReservationFunnelTest = (academyId: string) => {
  const router = useRouter();
  const isLinkFromAcademy = router.query.isLinkFromAcademy === 'true';
  const isTest = useFeatureFlagVariantKey('reduced-application-funnel-conversion') === 'test';

  if (!academyId) {
    console.error('Invalid academyId');
    return {
      isTest: false,
      shouldIgnoreTestFlag: false,
    };
  }

  return {
    isTest,
    shouldIgnoreTestFlag: ACADEMY_IDS_TO_IGNORE.includes(academyId) || isLinkFromAcademy,
  };
};

/**
 * 예약 퍼널 테스트 여부에 따라 퍼널 레슨 정보를 병합하는 훅입니다.
 *
 * academyId에 따라 예약 퍼널 테스트 여부를 확인하며,
 * 테스트인 경우 funnelLessonInfo가 존재하지 않으면 shortReservationFunnelData의 lessonInfo를 사용합니다.
 *
 * @param {object} params - 함수에 전달되는 매개변수 객체
 * @param {LessonInfo} params.funnelLessonInfo - 퍼널 레슨 정보
 * @param {string} params.academyId - 학원 고유 아이디
 * @returns {LessonInfo} 선택된 퍼널 레슨 정보를 반환합니다.
 */
export const useMergedLessonInfoOnTest = ({
  funnelLessonInfo,
  academyId: academyIdFromProps,
}: {
  funnelLessonInfo: LessonInfo | null;
  academyId?: string;
}) => {
  const router = useRouter();
  const academyId = academyIdFromProps ?? (router.query.id as string);
  const { isTest, shouldIgnoreTestFlag } = useIsReservationFunnelTest(academyId);
  const academy = useAtomValue(shortReservationAtoms.academy);
  const lesson = useAtomValue(shortReservationAtoms.lesson);
  const lessonInfo = academy?.lessons.find((l) => l.code === lesson.selectedLesson)?.lessonInfo;

  const shouldUseFunnelData = !isTest || shouldIgnoreTestFlag;

  const result = useMemo(
    () => (shouldUseFunnelData ? funnelLessonInfo : lessonInfo) ?? ({} as LessonInfo),
    [shouldUseFunnelData, funnelLessonInfo, lessonInfo],
  );

  return result;
};

/**
 * 예약 퍼널 테스트 여부에 따라 선택된 시간 또는 범위를 병합하는 훅입니다.
 *
 * academyId에 따라 예약 퍼널 테스트 여부를 확인하며,
 * 테스트인 경우 funnelSelectedTime 또는 funnelSelectedRange가 존재하지 않으면 shortReservationFunnelData의 selectedTime 또는 selectedRange를 사용합니다.
 *
 * @param {object} params - 함수에 전달되는 매개변수 객체
 * @param {SelectedTime} params.funnelSelectedTime - 퍼널 선택된 시간
 * @param {SelectedRange} params.funnelSelectedRange - 퍼널 선택된 범위
 * @param {string} params.academyId - 학원 고유 아이디
 * @returns {object} 선택된 시간 또는 범위를 반환합니다.
 */
export const useMergedSelectedTimeOrRangeOnTest = ({
  funnelSelectedTime,
  funnelSelectedRange,
  academyId: academyIdFromProps,
}: {
  funnelSelectedTime: SelectedTime | null;
  funnelSelectedRange: SelectedRange | null;
  academyId?: string;
}) => {
  const router = useRouter();
  const academyId = academyIdFromProps ?? (router.query.id as string);
  const { isTest, shouldIgnoreTestFlag } = useIsReservationFunnelTest(academyId);
  const { time: selectedTime, range: selectedRange } = useAtomValue(shortReservationDateTimeAtom);

  const shouldUseFunnelData = !isTest || shouldIgnoreTestFlag;

  const { selectedTime: selectedTimeResult, selectedRange: selectedRangeResult } = useMemo(
    () => ({
      selectedTime: shouldUseFunnelData ? funnelSelectedTime : selectedTime ?? null,
      selectedRange: shouldUseFunnelData ? funnelSelectedRange : selectedRange ?? null,
    }),
    [shouldUseFunnelData, funnelSelectedTime, funnelSelectedRange, selectedTime, selectedRange],
  );

  return { selectedTime: selectedTimeResult, selectedRange: selectedRangeResult };
};
