import { SelectedRange } from '@db/base-models';
import { atomWithSuperJsonLocalStorage } from '@jotaiStore/storage';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import type { DateSelection, LessonDisplayState } from '../types';

export type LessonState = {
  baseLesson?: {
    label: string;
    value: string;
  };
  variantLesson?: {
    label: string;
    value: string;
  };
  selectedLesson?: LessonCode;
};

export type DateTimeState = {
  id?: string;
  date?: DateSelection;
  dates: DateSelection[];
  time?: string;
};

export const shortReservationAtoms = {
  academy: atomWithSuperJsonLocalStorage<Academy | null>('short-reservation:academy', null),
  registrationType: atom<RegistType>('license'),
  lesson: atomWithStorage<LessonState>('short-reservation:lesson', {
    baseLesson: undefined,
    variantLesson: undefined,
    selectedLesson: undefined,
  }),
  selectedDateTime: atomWithStorage<DateTimeState>('short-reservation:date-time', {
    id: undefined,
    date: undefined,
    dates: [],
    time: undefined,
  }),
  timetable: atomWithSuperJsonLocalStorage<Timetable | null>('short-reservation:timetable', null),
  persistentLessonInfo: atomWithSuperJsonLocalStorage<{
    lessonInfo: LessonInfo | null;
    selectedTime: SelectedTime | null;
    selectedRange: SelectedRange | null;
  }>('short-reservation:persistent-lesson-info', {
    lessonInfo: null,
    selectedTime: null,
    selectedRange: null,
  }),
};

// Derived atom for available dates
export const shortReservationAvailableDatesAtom = atom((get) => {
  const lessonSelection = get(shortReservationAtoms.lesson);
  const timetable = get(shortReservationAtoms.timetable);

  if (!timetable || !lessonSelection.selectedLesson) {
    return {};
  }

  const availableDays = timetable.getAvailableDaysByStartAndEnd({
    startDay: new Date(),
    endDay: new Date(new Date().setDate(new Date().getDate() + 30)),
    lessonCode: lessonSelection.selectedLesson,
  });

  const availableDates = Object.entries(availableDays ?? {}).reduce<Record<string, boolean>>(
    (acc, [date, timeList]) => {
      acc[date] = timeList.some((time) => time.status);
      return acc;
    },
    {},
  );

  return availableDates;
});

// Derived atom for available times
export const shortReservationAvailableTimesAtom = atom((get) => {
  const lessonSelection = get(shortReservationAtoms.lesson);
  const dateTimeSelection = get(shortReservationAtoms.selectedDateTime);
  const timetable = get(shortReservationAtoms.timetable);

  if (
    !timetable ||
    !lessonSelection.selectedLesson ||
    (!dateTimeSelection.date?.date && dateTimeSelection.dates.length === 0)
  ) {
    return [];
  }

  const availableDays = timetable.getAvailableDaysByStartAndEnd({
    startDay: new Date(),
    endDay: new Date(new Date().setDate(new Date().getDate() + 30)),
    lessonCode: lessonSelection.selectedLesson,
  });

  if (dateTimeSelection.dates.length > 0) {
    // For multiple dates, return intersection of available times for all selected dates
    const allTimes = dateTimeSelection.dates.map((date) => {
      const timeList = availableDays[date.date] ?? [];
      return timeList
        .map((time) => {
          const { startTimeString, status, id } = time;
          if (!status) return null;
          return { id, startTimeString };
        })
        .filter(
          (
            time: { id: string; startTimeString: string } | null,
          ): time is { id: string; startTimeString: string } => time !== null,
        );
    });

    // Return times that are available for all selected dates
    return allTimes.reduce<{ id: string; startTimeString: string }[]>((acc, times) => {
      if (acc.length === 0) return times;
      return acc.filter((time) => times.includes(time));
    }, []);
  }

  // For single date selection
  const timeList = availableDays[dateTimeSelection.date?.date ?? ''] ?? [];
  return timeList
    .map((time) => {
      const { startTimeString, status, id } = time;
      if (!status) return null;
      return { id, startTimeString };
    })
    .filter(
      (
        time: { id: string; startTimeString: string } | null,
      ): time is { id: string; startTimeString: string } => time !== null,
    );
});

export const shortReservationDateTimeAtom = atom((get) => {
  const selectedLesson = get(shortReservationAtoms.lesson).selectedLesson;
  const selectedDateTime = get(shortReservationAtoms.selectedDateTime);
  const timetable = get(shortReservationAtoms.timetable);
  const type = selectedDateTime.dates.length > 0 ? ('range' as const) : ('single' as const);
  const selectedRange = selectedDateTime.dates.map((date) => new Date(date.date));

  if (!selectedLesson) {
    return {
      type: 'null' as const,
      range: null,
      time: null,
    };
  }

  if (type === 'range') {
    return {
      type,
      time: null,
      range: new SelectedRange({
        startDate: selectedRange[0],
        endDate: selectedRange[1],
      }) as SelectedRange,
    };
  }

  const { date, id } = selectedDateTime;
  const dateString = date?.date ?? '';
  const timeList = timetable?.getAvailableDaysInMonth({
    date: new Date(dateString),
    lessonCode: selectedLesson,
  });
  const selectedTimeList = timeList?.[dateString];

  const selectedTime: SelectedTime | null =
    selectedTimeList?.find((time) => time.id === id) ?? null;

  return {
    type,
    time: selectedTime,
    range: null,
  };
});

export const shortReservationArrivalMethodAtom = atom((get) => {
  const academy = get(shortReservationAtoms.academy);
  const selectedLesson = get(shortReservationAtoms.lesson).selectedLesson;
  if (!academy || !academy.lessons || !selectedLesson) return undefined;
  const methodMap = academy.lessons.reduce<Record<LessonCode, ArrivalMethod>>((acc, lesson) => {
    acc[lesson.code] = lesson.arrivalMethod;
    return acc;
  }, {} as Record<LessonCode, ArrivalMethod>);
  return methodMap[selectedLesson];
});

export const shortReservationPriceAtom = atom((get) => {
  const academy = get(shortReservationAtoms.academy);
  const selectedLesson = get(shortReservationAtoms.lesson).selectedLesson;
  if (!academy || !selectedLesson) return undefined;
  const found = academy.lessons.find((lesson) => lesson.code === selectedLesson);
  return found ? found.price : undefined;
});

export const shortReservationCanReserveAtom = atom((get) => {
  const lessonState = get(shortReservationAtoms.lesson);
  const dt = get(shortReservationAtoms.selectedDateTime);
  const arrivalMethod = get(shortReservationArrivalMethodAtom);
  if (arrivalMethod === 'firstVisit') {
    if (dt.dates.length !== 2) return false;
    const [start, end] = dt.dates;
    return !!lessonState.selectedLesson && start.date !== end.date;
  }
  return !!lessonState.selectedLesson && !!dt.date && !!dt.time;
});

export const shortReservationCalculatedAvailableDatesAtom = atom((get) => {
  const arrivalMethod = get(shortReservationArrivalMethodAtom);
  const availableDates = get(shortReservationAvailableDatesAtom);
  if (arrivalMethod !== 'firstVisit') {
    return availableDates;
  }
  return Object.fromEntries(
    Array.from({ length: 42 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() + i + 1);
      return [date.toISOString().split('T')[0], true];
    }),
  );
});

export const lessonDisplayStateAtom = atom<LessonDisplayState>({
  baseOpen: true,
  variantOpen: false,
});
