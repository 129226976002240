import { ComponentProps } from 'react';

import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { useGetUser } from '@db/collections';
import { BottomSheet, NewBoxButton, Spacer, useOverlay, useToast } from 'design-system';
import Image from 'next/image';
import { cn } from 'tailwind-config';

import { KakaoLoginButton } from '@components/Activation/KakaoLoginButton';
import {
  useMergedLessonInfoOnTest,
  useMergedSelectedTimeOrRangeOnTest,
} from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useApplicationFunnel } from '@templates/Application/hooks';
import { useIsIOSApp } from '@templates/Application/hooks/use-is-ios-app';

import { Funnel } from '@hooks/use-funnel';
import { usePageQuery } from '@hooks/use-handle-url';
import { getDeviceByUserAgent } from '@utils/Common';

import { PurchaseButton } from './PurchaseButton';

interface PurchaseBottomProps {
  purchaseCondition: ComponentProps<typeof PurchaseButton>['purchaseCondition'];
  isKeyInPurchase: boolean;
}

const LessonInfo = () => {
  const { discountResult, lessonInfo: funnelLessonInfo } = useApplicationFunnel<'Purchase'>();

  const lessonInfo = useMergedLessonInfoOnTest({
    funnelLessonInfo,
  });

  const { lessonName, lessonDiscountPrice } = lessonInfo;

  const totalDiscountPrice = discountResult.할인금 + lessonDiscountPrice;

  return (
    <>
      <div className="w-full pt-5">
        <div className="text-Body4 text-new-gray-500 leading-[22.4px]">{lessonName}</div>
        <div>
          {totalDiscountPrice > 0 ? (
            <div className="flex items-center gap-4">
              <div className="text-Body1 text-new-DTRed-400 leading-[25.6px]">
                {(discountResult.원금 + lessonDiscountPrice - totalDiscountPrice).toLocaleString()}
                원
              </div>
              <span className="text-[13px] text-gray-400 line-through	">
                {(discountResult.원금 + lessonDiscountPrice).toLocaleString()}원
              </span>
            </div>
          ) : (
            <div className="text-Body1 leading-[25.6px]">
              {discountResult.최종금.toLocaleString()}원
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const PurchaseBottom = ({ purchaseCondition, isKeyInPurchase }: PurchaseBottomProps) => {
  const { user } = useGetUser();
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();
  const {
    discounts,
    discountResult,
    lessonInfo: funnelLessonInfo,
    academy,
    visitorInfo,
    shuttleBusPreference,
    selectedTime: funnelSelectedTime,
    selectedRange: funnelSelectedRange,
    selectedCoupons,
  } = useApplicationFunnel<'Purchase'>();

  const { openToast } = useToast();
  const canPurchase = Object.values(purchaseCondition).every((condition) => condition.value);

  const isIOSApp = useIsIOSApp();

  const overlay = useOverlay();

  // 예약 퍼널 줄이기 AB 테스트 관련
  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy.id,
    funnelLessonInfo,
  });
  const { selectedTime, selectedRange } = useMergedSelectedTimeOrRangeOnTest({
    funnelSelectedTime,
    funnelSelectedRange,
    academyId: academy.id,
  });
  // 예약 퍼널 줄이기 AB 테스트 관련 끝

  if (!user)
    return (
      <Funnel.Bottom className={cn('flex bg-white', isIOSApp ? 'pb-32' : '')}>
        <LessonInfo />
        <div
          onClick={(e) => {
            const findCondition = Object.values(purchaseCondition).find(
              (condition) => !condition.value,
            );

            if (findCondition) {
              findCondition.ref?.scrollIntoView({ behavior: 'smooth' });

              openToast({
                title: findCondition.message,
              });
              e.preventDefault();
              return;
            }
          }}
        >
          <NewBoxButton
            label="결제하기"
            styles="filled-yellow"
            state={canPurchase ? 'active' : 'inactive'}
            onClick={() => {
              if (!canPurchase) return;

              overlay.open(({ isOpen, close }) => {
                return (
                  <BottomSheet
                    isOpen={isOpen}
                    onClose={close}
                    header={{
                      title: '예약을 위해 추가 정보가 필요해요.',
                      subTitle: '지금까지 입력한 정보는 그대로 유지돼요.',
                    }}
                    main={
                      <>
                        <div className="flex w-full items-center justify-center">
                          <Image src="/need-login.png" width={96} height={96} alt="로그인 필요" />
                        </div>
                        <Spacer className="h-[24px]" />
                        <KakaoLoginButton />
                        <Spacer className="h-[12px]" />
                      </>
                    }
                  />
                );
              });
            }}
          />
        </div>
      </Funnel.Bottom>
    );

  const {
    id: academyId,
    location: { bubAddress: academyLocation },
    name: academyName,
    phoneNum: academyPhoneNum,
    submallInfo: { subCoNo: academySubCoNo, subMid: academySubMid } = { subCoNo: '', subMid: '' },
    smallmallInfo: { smbsRank: academySmallRank } = { smbsRank: '' },
    paymentType,
    isSimulation,
    images,
  } = academy;

  const receipt: Omit<ReceiptData, 'id'> = {
    academyId,
    academySubCoNo,
    academySubMid,
    academySmallRank,
    academyLocation: academyLocation ?? '',
    academyName,
    academyPhoneNum: academyPhoneNum ?? '',
    image: images[0],
    deposit: discountResult ? discountResult.최종금 : 0,
    selectedTimes: selectedTime ? [selectedTime.get()] : [],
    selectedRange: selectedRange ? selectedRange.get() : null,
    userId: user.uid ?? '',
    userName: user.realName ?? user.name,
    userPhoneNum: user.phoneNum,
    userBirthday: user.birthday ?? '',
    userAddress: user.address ? user.address : '',
    userDetailAddress: user.detailAddress ? user.detailAddress : '',
    visitorName: visitorInfo.name,
    visitorBirthday: visitorInfo.birthday,
    visitorPhoneNum: visitorInfo.phoneNum.replaceAll('-', ''),
    shuttleBusPreference: shuttleBusPreference,
    device: getDeviceByUserAgent(),
    paymentType,
    isSimulation,
    applicationMode,
    ...lessonInfo,
  };

  return (
    <Funnel.Bottom className={cn('flex bg-white', isIOSApp ? 'pb-32' : '')}>
      <LessonInfo />
      <PurchaseButton
        purchaseCondition={purchaseCondition}
        receipt={receipt}
        addressString={user.address ? user.address + user.detailAddress : ''}
        isTogetherEvent={applicationMode === 'together-purchase'}
        isKeyInPurchase={isKeyInPurchase}
        discountPrice={discountResult ? discountResult?.할인금 : 0}
        totalPrice={discountResult ? discountResult.최종금 : 0}
        운전선생할인금={discountResult ? discountResult.운전선생할인금 : 0}
        시뮬레이션할인금={discountResult ? discountResult.시뮬레이션할인금 : 0}
        onClickPurchase={(purchaseMethod) => {
          const lesson = academy.selectLesson({
            code: lessonInfo.lessonCode,
            periodText: lessonInfo.lessonPeriodText,
            registType: lessonInfo.lessonRegistType as RegistType,
          });

          LogEvent.매출.$결제시작(academy, lesson, applicationMode, purchaseMethod, discounts);
        }}
        discounts={discounts}
        selectedCoupons={selectedCoupons}
      />
    </Funnel.Bottom>
  );
};
