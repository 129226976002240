import { useEffect, useMemo, useState } from 'react';

import { usePageQuery } from '@hooks/use-handle-url';

import { useApplicationFunnel } from './use-application-funnel';

export type ApplicationScreen = 'RegistTypeSelect' | 'LessonsSelect' | 'DateSelect' | 'Purchase';

export const useGetLessonByQuerystring = () => {
  const { academy, setSelectedRegistType } = useApplicationFunnel();

  const { lessonCode, registType, periodText } = usePageQuery<'/academy/[id]/application'>();

  const lesson = useMemo(() => {
    if (!academy || lessonCode === '') return;

    try {
      setSelectedRegistType(registType as RegistType);

      return academy.selectLesson({
        code: lessonCode as LessonCode,
        periodText: periodText as string,
        registType: registType as RegistType,
      });
    } catch (error) {
      return undefined;
    }
  }, [academy]);

  return lesson;
};

/**
 * 예약 퍼널 구조 상 수업 선택을 하면
 * 뒤의 퍼널이 달라져야 해서 screen을 변경하는 hook
 */
export const useHandleChangeScreen = () => {
  const lesson = useGetLessonByQuerystring();
  const { setLessonInfo } = useApplicationFunnel();
  const [screens, setScreens] = useState<ApplicationScreen[]>([]);
  const { applicationMode } = usePageQuery<'/academy/[id]/application'>();

  /**
   * set initial screens
   */
  useEffect(() => {
    if (applicationMode === 'together-select') {
      setScreens(['RegistTypeSelect', 'LessonsSelect', 'DateSelect']);
      return;
    } else if (applicationMode === 'together-purchase') {
      setScreens(['Purchase']);
      return;
    }
    setScreens(['RegistTypeSelect', 'LessonsSelect', 'DateSelect', 'Purchase']);
  }, [applicationMode, lesson]);

  /**
   * set initial lessonInfo
   */
  useEffect(() => {
    if (!lesson) return;
    setLessonInfo(lesson.lessonInfo);
  }, [lesson]);

  return screens;
};
