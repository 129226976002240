import { useGetUser } from '@db/collections';
import { Spacer } from 'design-system';
import { formatDate } from 'shared-values';

import {
  useMergedLessonInfoOnTest,
  useMergedSelectedTimeOrRangeOnTest,
} from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useApplicationFunnel } from '@templates/Application/hooks';

export const ReservationInfo = () => {
  const { user } = useGetUser();
  const {
    academy,
    lessonInfo: funnelLessonInfo,
    selectedTime: funnelSelectedTime,
    selectedRange: funnelSelectedRange,
  } = useApplicationFunnel<'Purchase'>();

  const { selectedTime, selectedRange } = useMergedSelectedTimeOrRangeOnTest({
    academyId: academy.id,
    funnelSelectedTime,
    funnelSelectedRange,
  });

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy.id,
    funnelLessonInfo,
  });

  const lessonName = lessonInfo.lessonName;

  const { name: academyName, isSimulation, displayName } = academy;

  return (
    <div className="py-32">
      <h4 className="text-new-Sub-Title text-new-gray-900">예약 정보</h4>
      <Spacer className="h-20" />
      <div className="flex gap-8">
        <div className="text-new-Body2-medium flex w-[62px] flex-col gap-12">
          <span className="text-new-gray-500">예약자명</span>
          <span className="text-new-gray-500">{`예약 ${isSimulation ? '연습장' : '학원'}`}</span>
          <span className="text-new-gray-500">{`예약 ${isSimulation ? '상품' : '수업'}`}</span>
          <span className="text-new-gray-500">예약 날짜</span>
        </div>
        <div className="text-new-Body2-medium flex flex-col gap-12">
          <span className="text-new-gray-900">{user && user.realName ? user.realName : '-'}</span>
          <span className="text-new-gray-900">{isSimulation ? academyName : displayName}</span>
          <span className="text-new-gray-900">{lessonName}</span>
          <span className="text-new-gray-900">
            {selectedTime &&
              `${formatDate(selectedTime.date, 'YY.MM.DD')} ${selectedTime.startEndString}`}
            {selectedRange &&
              (isSimulation
                ? formatDate(selectedRange.startDate, 'YY.MM.DD')
                : `${formatDate(selectedRange.startDate, 'YY.MM.DD')} ~ ${formatDate(
                    selectedRange.endDate,
                    'YY.MM.DD',
                  )}`)}
          </span>
        </div>
      </div>
    </div>
  );
};
