import { Banner, Spacer } from 'design-system';

import { useMergedLessonInfoOnTest } from '@components/ShortReservationFunnel/hooks/use-is-reservation-funnel-test';
import { useApplicationFunnel } from '@templates/Application/hooks';

interface ShuttleBusPreferenceListType {
  type: ShuttleBusPreference;
  text: string;
}

const shuttleBusPreferenceList: ShuttleBusPreferenceListType[] = [
  {
    type: 'yes',
    text: '학원 셔틀버스를 이용할게요',
  },
  {
    type: 'no',
    text: '학원에 직접 갈게요',
  },
  {
    type: 'unsure',
    text: '잘 모르겠어요',
  },
];

export const ShuttleBusPreference = () => {
  const {
    shuttleBusPreference,
    setShuttleBusPreference,
    academy,
    lessonInfo: funnelLessonInfo,
  } = useApplicationFunnel<'Purchase'>();
  const { isSimulation } = academy;

  const lessonInfo = useMergedLessonInfoOnTest({
    academyId: academy.id,
    funnelLessonInfo,
  });

  const is면허캠프 = lessonInfo?.lessonCode === 'TWO_AUTO_CAMP';

  if (isSimulation || academy.isSpecificAcademy('창동') || is면허캠프) return null;

  return (
    <>
      <Spacer className="h-32" />
      <h4 className="text-new-Sub-Title text-new-gray-900">셔틀버스 탑승 여부</h4>
      <ul className="flex flex-col gap-16 py-20">
        {shuttleBusPreferenceList.map((el) => (
          <li
            key={el.type}
            onClick={() => setShuttleBusPreference(el.type)}
            className="flex cursor-pointer items-center gap-6"
          >
            <input
              type="radio"
              readOnly
              checked={el.type === shuttleBusPreference}
              className="checked:border-new-DTYellow-400 h-24 w-24 border-[1.6px] border-gray-200 bg-white checked:border-[6px]"
            />
            <label className="text-new-gray-900 text-new-Body1-medium">{el.text}</label>
          </li>
        ))}
      </ul>
      <Banner
        description="셔틀버스 탑승을 위해 학원에 연락이 올 수 있습니다."
        size="small"
        type="content-left"
        icon="info-outlined"
      />
      <Spacer className="h-32" />
    </>
  );
};
