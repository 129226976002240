import { useState } from 'react';

import { 당일예약가능학원 } from '@db/collections';
import { NewBoxButton, useToast } from 'design-system';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Tomorrow, formatDate } from 'shared-values';

import { FormStyle } from '@templates/Application/components';
import { ApplicationBottom } from '@templates/Application/components/ApplicationBottom';
import { useApplicationFunnel } from '@templates/Application/hooks';

import { BlockAcademyIds } from '../../DateSelect';
import { SelectedScheduleIndicator } from '../SelectedScheduleIndicator';

const DateRangePicker = dynamic(() => import('./components').then((v) => v.DateRangePicker), {
  ssr: false,
});

export const DateRangeCalendar = () => {
  const {
    academy: { id: academyId },
    selectedRange,
    setSelectedRange: setFunnelSelectedRange,
  } = useApplicationFunnel<'DateSelect'>();

  const router = useRouter();
  const { openToast } = useToast();

  const { fromOnsiteEvent = '' } = router.query;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  return (
    <>
      <FormStyle
        title="원하시는 기간을 선택해 주세요."
        description="선택한 기간 내 가능한 교육 일정이 안내될 예정입니다."
      >
        <DateRangePicker
          setSelectedRange={setFunnelSelectedRange}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          minDate={
            BlockAcademyIds.includes(academyId)
              ? new Date('2030-12-31')
              : fromOnsiteEvent === 'true' || 당일예약가능학원.includes(academyId)
              ? new Date()
              : Tomorrow
          }
        />
      </FormStyle>
      {startDate && (
        <SelectedScheduleIndicator
          dateType="single"
          selectedSchedule={`${formatDate(startDate, 'YY.MM.DD')} ~ ${formatDate(
            endDate,
            'YY.MM.DD',
          )}`}
        />
      )}
      <ApplicationBottom>
        {({ onClick, disabled }) => (
          <div
            onClick={() => {
              if (selectedRange) return;
              if (startDate === null || endDate === null) {
                openToast({
                  title: '시작일과 종료일을 모두 선택해 주세요.',
                  yPosition: 80,
                });
              }
            }}
          >
            <NewBoxButton
              label="다음"
              fill
              styles="filled-yellow"
              onClick={onClick}
              disabled={disabled}
            />
          </div>
        )}
      </ApplicationBottom>
    </>
  );
};
